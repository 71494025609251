.lvlContainer {
  position: relative;
  height: 72px;
}

.shape {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 586px;
  height: 44px;
  text-align: center;
  background-color: black;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 23%,
    rgb(69, 245, 224) 100%
  );
  clip-path: polygon(-24px 0, 0% 0, 0% 0px, 0% 0, 106% 0, 86% 100%, 14% 100%);
}

.shape::before {
  position: absolute;
  width: 100%;
  max-width: 586px;
  height: 33px;
  content: "";
  top: 10px;
  left: 0px;
  right: 0px;
  max-width: 585px;
  background: #12121e;
  clip-path: polygon(
    -13px -7px,
    0% 0px,
    100% 0px,
    100% 0px,
    calc(100% - -1px) 0px,
    calc(86% - 3px) 105%,
    calc(14% - 0px) 100%
  );
}

@media (max-width: 386px) {
  .shape::before {
    clip-path: polygon(
      -15px -7px,
      0% 0px,
      100% 0px,
      100% 0px,
      calc(100% - -1px) 0px,
      calc(86% - 3px) 105%,
      calc(14% - 0px) 100%
    );
  }
}

.text {
  top: 17px;
  position: absolute;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.spn {
  display: inline-block;
}

.subtext {
  position: absolute;
  top: 40px;
  width: 100%;
  color: #8f8f8f;
  font-size: 12px;
  font-weight: 300;
  bottom: 4px;
  z-index: 2;
}
