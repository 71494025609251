.background {
  position: absolute;
  width: 100%;
  max-width: 601px;
  height: 201px;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(25, 25, 36, 0) 4.1%,
    rgba(18, 18, 30, 0.7) 18.96%,
    #0d0d0e 100%
  );
  z-index: 0;
}

.wrapper {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  .header {
    padding: 21px 0 28px;
    width: 100%;
    font-family: "Days One";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15em;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      color: #cefffbee;
    }

    .icon {
      position: absolute;
      right: 25px;
      width: 20px;
      height: 19px;
      cursor: pointer;
      @media (max-width: 320px) {
        right: 7px;
      }
    }
  }

  .rewardPool {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 38px;

    .subtitle {
      width: 100%;
      font-family: "Plus Jakarta Sans";
      font-size: 12px;
      font-weight: 300;
      color: #828282;
    }
  }
}
