.closed {
  display: none;
}

.wrapper {
  position: absolute;
  inset: 0 0 0 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  width: 363px;
  height: 308px;
  background-image: url("../../assets/roundModalBG.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  font-family: "Plus Jakarta Sans";
}

.rewardsContainer {
  min-width: 139px;
  padding: 9px 17px;
  display: flex;
  background-color: #1e1e1e;
  border-radius: 12px;
  gap: 16px;
}

.title {
  margin: 0;
  font-family: "Days One";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.rewardTitle {
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: #bababa;
  text-align: start;
}

.reward {
  font-size: 14px;
  font-weight: 800;
  line-height: 25.79px;
  letter-spacing: 0.06em;
  text-align: center;
}
