.wrapper {
  position: relative;
  width: 156px;
  height: 60px;
  border: 1px solid;
  border-right: none;
  border-left: none;
  border-image-slice: 1;
  border-width: 1px;
  display: flex;
}

.firstBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.firstBlockTitle {
  position: absolute;
  top: 5px;
  left: 40px;
  width: fit-content;
  font-size: 9px;
  font-weight: 200;
  letter-spacing: 0.05em;
  color: #696969;
}

.iconsNumsContainer {
  display: flex;
  gap: 1px;
}

.numsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
}

.numbers {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.slashHour {
  height: auto;
  letter-spacing: 1px;
  color: #696969;
}

.secondBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 0px;
  width: 31px;
  height: 31px;
}
