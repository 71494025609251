.wrapper {
  position: relative;
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c4d0ff;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 2px;
}

.borders {
  position: relative;
  width: 156px;
  height: 60px;
  border: 1px solid;
  border-right: none;
  border-left: none;
  border-image-slice: 1;
  border-width: 1px;
}

.iconTitleContainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 15px;
}

@media (max-width: 414px) {
  .iconTitleContainer {
    gap: 5px;
  }
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-family: "Days One";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.button {
  margin: 0 23px 0 23px;
  position: absolute;

  z-index: 2;
  display: flex;
  justify-content: center;
}

@media (max-width: 414px) {
  .button {
    margin: 0 2px 0 2px;
  }
}
