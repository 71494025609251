.wrapper {
  width: 224px;
  height: 66px;
  background-repeat: no-repeat;
  background-position: cover;
  font-family: "Days One";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.82px;
  letter-spacing: 0.13em;
  color: #ffffff;
  background-image: url("../../assets/learnMoreBtn.svg");
}

.text {
  display: inline-block;
  margin-top: 7px;
}
