@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Rubik:ital,wght@0,300..900;1,300..900&family=Saira:ital,wght@0,100..900;1,100..900&display=swap");

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  padding: 50px 0 0 45px;
  letter-spacing: 1.5px;
  flex-direction: column;
}

.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 11px;
  font-family: "Saira";
  font-size: 19px;
  font-weight: 400;
}

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
}

.linearGradientBg {
  position: absolute;
  width: 100%;
  top: 410px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(18, 18, 30, 0.8) 4.1%,
    rgba(18, 18, 30, 0.919) 18.96%,
    #06060a 100%
  );
}
