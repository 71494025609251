.thingWrapNone {
  display: none;
}

.thingWrap {
  position: absolute;
  width: 5%;
  display: flex;
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  animation: thing 2.2s ease forwards;

  @keyframes thing {
    0%,
    50% {
      display: none;
      width: 5%;
      opacity: 0;
    }

    51% {
      opacity: 1;
      display: flex;
    }

    100% {
      width: 52%;
      opacity: 1;
    }
  }

  .iconContainer {
    width: 15%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: var(--width-var);
    }
  }
}
