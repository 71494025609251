.midCircle {
  position: absolute;
  height: 35%;
  flex-grow: 1;
  z-index: 2;
}

.midAnimated {
  height: 0%;
  animation: mid 0.8s 0.4s forwards;
}

@keyframes mid {
  0% {
    height: 0;
    transform: scale(0);
  }

  90%,
  100% {
    height: 46%;
    transform: scale(1);
  }
}
