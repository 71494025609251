.wrapper {
  position: relative;
  /* width: 100%; */
  max-width: 292px;
  max-height: 292px;
  display: grid;
  justify-items: center;
  align-items: center;
  flex-grow: 1;

  .resultBox {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .rollingResult {
      position: absolute;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      display: flex;
    }
  }

  .buttonImg {
    width: 55px;
    height: 80px;
  }

  .buttonImgSM {
    width: 25px;
    height: 78px;
  }
}

.wrapperAnimated {
  animation: grow 1s;
}
@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
