.wrapper {
  padding: 0 0 22px 0;
  font-family: "Plus Jakarta Sans";
  font-size: 10px;
  font-weight: 200;
  line-height: 12.6px;
  letter-spacing: 0.15em;
}

.cs2 {
  font-family: "Plus Jakarta Sans";
  font-size: 10px;
  font-weight: 800;
  line-height: 12.6px;
  letter-spacing: 0.15em;
}
