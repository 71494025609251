.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.infoIconContainer {
  width: 29px;
  height: 32px;
}

.icon {
  width: 100%;
  height: 100%;
}

.nums {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.secondPart {
  letter-spacing: -1px;
  color: #696969;
}

.bulSecondPart {
  margin-top: 3px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700;
  color: #696969;
  letter-spacing: 0px;
}
