.wrapper {
  margin: 0 auto;
  padding: 0 35px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 94px;
  max-width: 601px;
  background-color: #0c0c0c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px 8px 15px -10px rgba(33, 33, 33, 1);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.iconContainer {
  width: 28px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: #696969;
}
