.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .rollWrapper {
    position: relative;
    padding: 0 30px;
    width: 100%;
    min-height: 100%;
    max-width: 601px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .rollWrapperGrid {
    position: relative;
    padding: 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      [col-start] minmax(100px, 1fr) [col-end]
    );
    gap: 26px;
    justify-items: center;

    @media (max-width: 601px) {
      grid-template-columns: repeat(
        auto-fill,
        [col-start] minmax(120px, 1fr) [col-end]
      );
    }
    @media (max-width: 486px) {
      grid-template-columns: repeat(
        auto-fill,
        [col-start] minmax(100px, 1fr) [col-end]
      );
    }
    @media (max-width: 413px) {
      grid-template-columns: repeat(
        auto-fill,
        [col-start] minmax(70px, 1fr) [col-end]
      );
    }
  }
}
