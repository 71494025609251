.button {
  font-family: "Days One";
  width: 153px;
  height: 43px;
  top: 499px;
  left: 130px;
  gap: 0px;
  border-radius: 12px;
  border: 1px solid #588ece;
  background-color: #3c83d7;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.14em;
  box-shadow: 0px 0px 3.5px 1px #4c68b3;
}

.text {
  filter: drop-shadow(0px 0px 4.5px #000000);
}
