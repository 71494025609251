.wrapper {
  position: relative;
  width: 194px;
  height: 163px;
  display: flex;
  justify-content: center;

  .linearBorder {
    width: inherit;
    height: inherit;
    position: absolute;
    border-radius: 30px;
    border: 1px solid transparent;
    background: linear-gradient(#55f2e5 10%, rgba(22, 35, 41, 0.5) 52%)
      border-box;
    mask: linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
  }

  .coinsIcon {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 70px;
    top: 32px;
  }

  .rewardAmount {
    position: absolute;
    top: 87px;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.06em;
    text-align: center;
  }

  .timerWrapper {
    position: absolute;
    top: 130px;
    display: flex;
    gap: 8px;

    .time {
      width: 39px;
      height: 48px;
      border-radius: 8px;
      background: #434358;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .nums {
        font-family: "Roboto";
        font-size: 15px;
        font-weight: 700;
      }

      .name {
        font-family: "Roboto";
        font-size: 9px;
        font-weight: 500;
        color: #8f8f8f;
        text-transform: uppercase;
      }
    }
  }
}
