.c1 {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.c1Animated {
  animation: roll 2s ease 0.7s none;

  @keyframes roll {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-180deg);
    }
  }
}
