.dashesWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .dashesIcon {
    position: absolute;
    height: 41%;
    opacity: 0.2;
  }
}

.dashesWrapperAnim {
  position: absolute;
  height: 0%;
  animation: dashes 0.8s 0.4s forwards;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes dashes {
    0% {
      height: 0;
      transform: scale(0);
    }

    90%,
    100% {
      height: 87%;
      transform: scale(1);
    }
  }

  .dashesIconAnim {
    position: absolute;
    height: 0%;
    opacity: 0;
    animation: dashesIcon 0.8s ease 0.4s forwards, compass 9s 0.2s infinite;

    @keyframes dashesIcon {
      0% {
        height: 0;
        transform: scale(0);
        opacity: 1;
      }

      10% {
        height: 0;
        transform: scale(0);
        opacity: 1;
      }

      30%,
      40% {
        opacity: 1;
        height: 100%;
        transform: scale(1);
      }

      45%,
      50% {
        opacity: 0;
        height: 100%;
        transform: scale(1);
      }

      58% {
        height: 100%;
        transform: scale(1);
        opacity: 1;
      }
      60% {
        height: 100%;
        transform: scale(1);
        opacity: 0;
      }

      85% {
        height: 100%;
        transform: scale(1);
        opacity: 0.2;
        display: flex;
      }

      100% {
        height: 100%;
        transform: scale(1);
        opacity: 0.2;
      }
    }

    @keyframes compass {
      0%,
      11% {
        transform: rotate(0deg);
      }

      18% {
        transform: rotate(35deg);
      }

      19% {
        transform: rotate(30deg);
      }

      23% {
        transform: rotate(38deg);
      }

      27% {
        transform: rotate(0deg);
      }

      31% {
        transform: rotate(38deg);
      }

      35% {
        transform: rotate(0deg);
      }

      39% {
        transform: rotate(32deg);
      }

      42% {
        transform: rotate(26deg);
      }

      47% {
        transform: rotate(38deg);
      }

      51% {
        transform: rotate(28deg);
      }

      56% {
        transform: rotate(40deg);
      }

      58% {
        transform: rotate(35deg);
      }

      60% {
        transform: rotate(20deg);
      }

      62% {
        transform: rotate(38deg);
      }

      64% {
        transform: rotate(25deg);
      }

      66% {
        transform: rotate(38deg);
      }

      70% {
        transform: rotate(10deg);
      }

      75% {
        transform: rotate(38deg);
      }

      80% {
        transform: rotate(0deg);
      }

      85% {
        transform: rotate(38deg);
      }

      90% {
        transform: rotate(10deg);
      }

      93% {
        transform: rotate(38deg);
      }

      95% {
        transform: rotate(29deg);
      }

      98% {
        transform: rotate(0deg);
      }

      0%,
      100% {
        transform: rotate(29deg);
      }
    }
  }
}
