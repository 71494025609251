@mixin flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.usersList {
  padding: 26px 15px 8px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 11px;
  overflow-y: scroll;

  .user {
    padding: 9px 46px 9px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 17px;
    border-radius: 7px;

    .firstBlock {
      display: flex;
      gap: 17px;
      border-radius: 7px;

      .nameAndBonus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;

        .name {
          font-family: "Plus Jakarta Sans";
          font-size: 12px;
          font-weight: 600;
        }

        .bonus {
          font-family: "Plus Jakarta Sans";
          font-size: 15px;
          font-weight: 500;
          color: #00e890;
          letter-spacing: 1.5px;
          @include flex-centered;
          gap: 10px;

          .coinContainer {
            @include flex-centered;

            .coin {
              width: 17px;
              height: 18px;
            }
          }

          .bonusText {
            @include flex-centered;
          }
        }
      }
    }

    .positionInList {
      width: 26px;
      height: 26px;
      font-family: "Plus Jakarta Sans";
      @include flex-centered;
    }
  }
}
