.closed {
  display: none;
}

.wrapper {
  position: absolute;
  inset: 0 0 0 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  justify-content: center;
  width: 340px;
  height: 410px;
  background-image: url("../../assets/modalBG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0;
  font-family: "Days One";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.rewards {
  margin-top: 32px;
  font-family: "Plus Jakarta Sans";
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  color: #bababa;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.curBonus {
  margin-left: 16px;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.06em;
  color: #c4d0ff;
}

.explain {
  margin-left: 7px;
  font-size: 9px;
  font-style: italic;
  font-weight: 300;
}

.rewIcon {
  position: relative;
  margin-top: 47px;
  display: flex;
  flex-direction: column;
}

.rewBackground {
  position: absolute;
  width: 126px;
  height: 126px;
  top: -30px;
  left: -36px;
  /* z-index: -1; */
  background-image: url("../../assets/modalRewardBG.svg");
}

.rewAmount {
  margin-top: 5px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 800;
  line-height: 25.79px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #00e890;
  z-index: 2;
}

.button {
  margin-top: 32px;
}
