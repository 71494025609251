.link {
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-image: url("../../assets/info-page/infoLinks.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.title {
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #ffffff;
}
