.c2 {
  position: absolute;
  height: 70%;
  flex-grow: 1;
  z-index: 1;
}

.c3 {
  position: absolute;
  height: 69%;
  flex-grow: 1;
  z-index: 0;
}

.c3Animated {
  animation: c3 0.8s 0.01s none;

  @keyframes c3 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

.c4 {
  position: absolute;
  height: 35%;
  flex-grow: 1;
}

.c4Animated {
  animation: c4 0.8s 0.07s none;

  @keyframes c4 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

.c5Animated {
  animation: c5 0.8s 0.12s none;

  @keyframes c5 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

.c6Animated {
  animation: c6 0.8s 0.17s none;

  @keyframes c6 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
