.noArrows {
  display: none;
}

.arrows {
  width: 140%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: arrowsContainer 2.5s ease 1s forwards infinite;
  @keyframes arrowsContainer {
    0% {
      width: 140%;
    }
    55% {
      width: 100%;
    }

    0%,
    100% {
      width: 125%;
    }
  }

  .arrowL,
  .arrowR {
    width: var(--length);
  }

  .arrowR {
    transform: rotate3d(0, -1, 0, -0.25turn);
    animation: arrowR 1.8s 1s forwards;
  }

  @keyframes arrowR {
    from {
      transform: rotate3d(0, -1, 0, -0.25turn);
    }
    to {
      transform: rotate3d(0, -1, 0, 0turn);
    }
  }

  .arrowL {
    transform: rotate3d(0, -1, 0, 0.25turn);
    animation: arrowL 1.8s 1s forwards;
  }

  @keyframes arrowL {
    from {
      transform: rotate3d(0, -1, 0, 0.25turn);
    }
    to {
      transform: rotate3d(0, -1, 0, 0turn);
    }
  }
}
