.wrapper {
  display: flex;
  flex-direction: column;
  gap: 51px;
}

.buttonsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 31px;
}
