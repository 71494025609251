.wrapper {
  width: 100%;
  height: 100%;
  max-width: 601px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  z-index: 2;

  .outlet {
    height: 100%;
  }

  .footerContainer {
    height: 94px;
    z-index: 2;
  }

  .gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 601px;
    height: 50%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(8, 8, 8, 0) 0%, #080808 100%);
  }
}

.wrapper2 {
  width: 100%;
  max-width: 601px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .gradient {
    position: absolute;
    bottom: 0;
    width: inherit;
    height: 50%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(8, 8, 8, 0) 0%, #080808 100%);
  }
}
